import "./App.css";

import React, { useEffect } from "react";
import Loading from "./components/Loading";
export default function App() {
	useEffect(() => {
		if (window.location.href.includes("cashpaypharmacies.com")) {
			window.location.replace("https://bookmypharmacy.com/cashpay");
			window.close();
		} else if (window.location.href.includes("wellpharmacypenticton.ca")) {
			window.location.replace("https://bookmypharmacy.com/7784761492");
			window.close();
		}
	}, []);

	return (
		<div className="App">
			<header className="App-header">
				<Loading height={200} width={200} />
			</header>
		</div>
	);
}
